import jobMutations from './job'
import utilMutations from './utils'
import userMutations from './user'
import orgMutations from './org'
import interviewMutations from './interview'
import emailTemplateMutations from './emailTemplates'
import folderMutations from './question-folders'

const mutations = Object.assign(
    jobMutations,
    utilMutations,
    userMutations,
    orgMutations,
    interviewMutations,
    emailTemplateMutations,
    folderMutations,
)

export default mutations
