<template>
    <div :class="$style.wrapper">
        <div :class="{ [$style.header]: true, [$style.white]: !isToday, [$style.blue]: isToday }">
            <Button
                color="primaryText"
                @click="currentDate = new Date()"
                :class="$style.jumpButton"
                v-if="!isToday"
            >
                <span slot="buttonText"> Jump to today </span>
            </Button>
            <span v-else :class="$style.headerSpacer"></span>
            <div :class="$style.headerContent">
                <FontAwesomeIcon
                    @click="previousDay"
                    :icon="faChevronLeft"
                    :class="$style.navigationIcon"
                />
                <div :class="$style.headerMiddle">
                    <div :class="$style.dateAndCount">
                        <span :class="$style.date">{{ formattedDate }}</span>
                        <span :class="$style.interviewCount">{{ interviewCount }}</span>
                    </div>
                    <span
                        :class="{
                            [$style.dayHeader]: true,
                            [$style.notTodayHeader]: !isToday,
                            [$style.todayHeader]: isToday,
                        }"
                        >{{ headerText }} interviews</span
                    >
                </div>
                <FontAwesomeIcon
                    @click="nextDay"
                    :icon="faChevronRight"
                    :class="$style.navigationIcon"
                />
            </div>
        </div>
        <div :class="$style.timezoneBanner">
            <FontAwesomeIcon :icon="faInfoCircle" :class="$style.infoCircleIcon" />
            Interview times listed in your timezone ({{ GMTOffset }})
        </div>
        <div :class="$style.interviewList">
            <div v-if="timeslots.length === 0" :class="$style.emptyStateText">
                No interviews have been booked for today. Check individual roles for any updates on
                interviews.
            </div>
            <div
                v-for="timeslot in timeslots"
                :key="timeslot.id"
                :class="[$style.interviewCard, getInterviewStatusClass(timeslot)]"
            >
                <div :class="$style.interviewDetails">
                    <div :class="$style.leftColumn">
                        <p :class="$style.candidateName">
                            {{ timeslot.Interviews[0].Application.User.firstName }}
                            {{ timeslot.Interviews[0].Application.User.lastName }}
                        </p>
                        <p :class="$style.smallText">{{ timeslot.Job.title }}</p>
                        <p :class="$style.smallText">
                            {{ timeslot.Interviews[0].InterviewType.name }}
                        </p>
                    </div>
                    <div :class="$style.rightColumn">
                        <div :class="$style.dateAndIcon">
                            <p :class="[$style.interviewDate, getInterviewDateClass(timeslot)]">
                                {{ formatTime(timeslot.startsAt, false) }}-{{
                                    formatTime(calculateEndsAt(timeslot.startsAt, timeslot.minutes))
                                }}
                            </p>
                            <div :class="$style.iconContainer">
                                <FontAwesomeIcon
                                    @click.stop="togglePopup(timeslot.id)"
                                    :icon="faEllipsisV"
                                    :class="$style.ellipsisIcon"
                                />
                                <div v-if="showPopup === timeslot.id" :class="$style.popupMenu">
                                    <a :href="`/manage/${timeslot.Job.id}/calendar`" target="_blank"
                                        >Manage interview details</a
                                    >
                                    <a :href="`/manage/${timeslot.Job.id}`" target="_blank"
                                        >View role</a
                                    >
                                </div>
                            </div>
                        </div>
                        <Chip
                            v-if="getChipContent(timeslot) !== null"
                            :text="getChipContent(timeslot)"
                            :color="getChipColour(timeslot)"
                            :class="$style.chip"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft, faChevronRight, faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import { isPast, isFuture, isToday, format, addDays, subDays, parseISO, addMinutes } from 'date-fns'
import { Chip } from '@applied/marmot'
import mapIcons from '@/utils/mapIcons'

export default {
    name: 'InterviewWidget',
    components: {
        FontAwesomeIcon,
        Chip,
    },
    data() {
        return {
            currentDate: new Date(),
            showPopup: false,
        }
    },
    watch: {
        currentDate() {
            this.fetchInterviews()
        },
    },
    computed: {
        ...mapIcons({ faEllipsisV, faChevronLeft, faChevronRight, faInfoCircle }),
        timeslots() {
            return this.$store.state.orgInterviews
        },
        formattedDate() {
            return format(this.currentDate, 'MMMM dd, yyyy')
        },
        interviewCount() {
            return this.timeslots?.length
        },
        isToday() {
            return isToday(this.currentDate)
        },
        GMTOffset() {
            const offset = new Date().getTimezoneOffset()
            const hours = Math.abs(Math.floor(offset / 60))
            const minutes = Math.abs(offset % 60)
            const sign = offset <= 0 ? '+' : '-'
            return `GMT${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
        },
        headerText() {
            // eslint-disable-next-line no-nested-ternary
            return this.isToday ? "Today's" : isFuture(this.currentDate) ? 'Upcoming' : 'Past'
        },
    },
    methods: {
        formatTime(date, withAMPM = true) {
            return format(parseISO(date), `hh:mm${withAMPM ? 'a' : ''}`)
        },
        calculateEndsAt(startsAt, minutes) {
            const startDate = parseISO(startsAt)
            return addMinutes(startDate, minutes).toISOString()
        },
        getChipContent(timeslot) {
            const endsAt = parseISO(this.calculateEndsAt(timeslot.startsAt, timeslot.minutes))

            if (isPast(endsAt)) {
                return 'Completed'
            }
            if (isPast(parseISO(timeslot.startsAt)) && isFuture(endsAt)) {
                return 'In progress'
            }

            return null
        },
        getChipColour(timeslot) {
            const endsAt = parseISO(this.calculateEndsAt(timeslot.startsAt, timeslot.minutes))
            if (isPast(endsAt)) {
                return 'great'
            }
            if (this.isInProgress(timeslot)) {
                return 'primary'
            }

            return null
        },
        getInterviewStatusClass(timeslot) {
            const startsAt = parseISO(timeslot.startsAt)
            const endsAt = parseISO(this.calculateEndsAt(timeslot.startsAt, timeslot.minutes))

            if (isPast(endsAt)) {
                return this.$style.pastInterview
            }
            if (this.isInProgress(timeslot)) {
                return this.$style.currentInterview
            }
            if (isFuture(startsAt)) {
                return this.$style.futureInterview
            }
            return null
        },
        isInProgress(timeslot) {
            const endsAt = parseISO(this.calculateEndsAt(timeslot.startsAt, timeslot.minutes))
            return isPast(parseISO(timeslot.startsAt)) && isFuture(endsAt)
        },
        getInterviewDateClass(timeslot) {
            const interviewDate = parseISO(timeslot.startsAt)
            if (this.isInProgress(timeslot) || isFuture(interviewDate)) {
                return this.$style.bold
            }

            return null
        },
        previousDay() {
            this.currentDate = subDays(this.currentDate, 1)
        },
        nextDay() {
            this.currentDate = addDays(this.currentDate, 1)
        },
        fetchInterviews() {
            this.$store.dispatch('fetchOrgInterviewsByDate', {
                date: format(this.currentDate, 'yyyy-MM-dd'),
            })
        },
        togglePopup(id) {
            this.showPopup = this.showPopup === id ? false : id
        },
        handleClickOutside(event) {
            const popupMenu = this.$el.querySelector(`.${this.$style.popupMenu}`)
            if (popupMenu && !popupMenu.contains(event.target)) {
                this.showPopup = false
            }
        },
    },
    mounted() {
        this.fetchInterviews()
        document.addEventListener('click', this.handleClickOutside)
    },
    beforeDestroy() {
        document.addEventListener('click', this.handleClickOutside)
    },
}
</script>

<style module src="./InterviewWidget.css" />
