<template>
    <div :class="$style.pipelineStatsContainer" ref="pipelineStatsContainer">
        <div
            v-for="data in enhancedPipelineStats"
            v-bind:key="data.key"
            :class="$style.pipelineStatsInnerContainer"
        >
            <div :class="$style.pipelineStatsNumberContainer">
                <div :class="$style.flex">
                    <p :class="$style.statNumber" :aria-label="data.ariaText">
                        {{ data.count === 0 ? '-' : data.count }}
                    </p>
                </div>
                <div
                    :class="[data.count > 0 ? $style.pipelineStatWithValue : $style.pipelineStatEmpty]"
                >
                    {{ data.label }}
                </div>
            </div>

            <div v-if="shouldShowInterviewProgress(data.key)" :class="$style.reviewProgressSection">
                <Tooltip
                    :class="$style.icon"
                    placement="top-start"
                    :offset="0"
                    trigger="click"
                >
                    <template #default>
                        <div><FontAwesomeIcon :icon="data.icon" /> Progress</div>
                    </template>
                    <template #inner>
                        <InterviewProgressTooltip 
                            :jobId="jobId"
                            :progress="validInterviewProgress" 
                            @close="handleTooltipClose"
                        />
                    </template>
                </Tooltip>
            </div>
            <div v-if="shouldShowReviewProgress(data.key)" :class="$style.reviewProgressSection">
                <Tooltip
                    :class="$style.icon"
                    placement="top-start"
                    :offset="0"
                    trigger="click"
                >
                    <template #default>
                        <div><FontAwesomeIcon :icon="data.icon" /> Progress</div>
                    </template>
                    <template #inner>
                        <ReviewProgressTooltip
                            :jobId="jobId"
                            :reviewProgress="reviewProgress"
                            @close="handleTooltipClose"
                        />
                    </template>
                </Tooltip>
            </div>
        </div>
    </div>
</template>

<script>
import { Tooltip } from '@applied/marmot'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEye, faCalendarDay } from '@fortawesome/pro-light-svg-icons'
import sharedConfig from 'sharedConfig'
import ReviewProgressTooltip from './ReviewProgressTooltip/ReviewProgressToolTip'
import InterviewProgressTooltip from './InterviewProgressTooltip/InterviewProgressTooltip'

const hiringStages = sharedConfig.default.hiringStages.default

export default {
    name: 'PipelineStatsSection',
    components: {
        FontAwesomeIcon,
        Tooltip,
        ReviewProgressTooltip,
        InterviewProgressTooltip,
    },
    props: {
        pipelineStats: Object,
        reviewProgress: {
            type: Object,
            default: () => ({}),
        },
        interviewProgress: {
            type: Array,
            default: () => ([]),
        },
        jobId: Number,
    },
    computed: {
        enhancedPipelineStats() {
            let countApplications

            if (!this.pipelineStats) {
                countApplications = () => 0
            } else {
                countApplications = (stage) =>
                    hiringStages[stage].statKeys.reduce(
                        (count, key) => count + this.pipelineStats[key],
                        0,
                    )
            }

            return Object.entries(this.stageConfig).map(([stage, config]) => {
                const count = countApplications(stage)
                const { title } = hiringStages[stage]

                return {
                    key: stage,
                    ...config,
                    count,
                    title,
                    ariaText: this.getAriaText(title, count),
                }
            })
        },
        stageConfig() {
            return {
                awaitingReview: {
                    label: 'NEEDS REVIEW',
                    statKeys: ['awaitingReview'],
                },
                inReviewOrAwaitingInterview: {
                    icon: faEye,
                    label: 'IN REVIEW',
                    statKeys: ['inReview', 'awaitingInterview'],
                },
                inInterviewOrAwaitingDecision: {
                    label: 'IN INTERVIEW',
                    statKeys: ['inInterview', 'awaitingDecision'],
                    icon: faCalendarDay,
                },
                rejectedAfterSubmission: {
                    label: 'REJECTED',
                    statKeys: ['rejectedAfterSubmission'],
                },
                hired: {
                    label: 'HIRED',
                    statKeys: ['hired'],
                },
            }
        },
        validInterviewProgress() {
            return this.interviewProgress.filter(
                (progress) => (
                    progress.invited > 0 ||
                    progress.booked > 0 ||
                    progress.done > 0
            ))
        }
    },
    methods: {
        getAriaText(title, count) {
            const isSingular = count === 1
            const verb = isSingular ? 'is' : 'are'
            const plural = isSingular ? '' : 's'

            return `There ${verb} ${count} candidate${plural} in stage '${title}'`
        },
        shouldShowReviewProgress(stage) {
            return (
                stage === 'inReviewOrAwaitingInterview' && 
                (this.reviewProgress.sift?.totalReviewCount > 0 || 
                this.reviewProgress.cv?.totalReviewCount > 0)
            )
        },
        shouldShowInterviewProgress(stage) {
            return (
                stage === 'inInterviewOrAwaitingDecision' &&
                this.validInterviewProgress.length > 0
            )
        },
        handleTooltipClose() {
            this.$refs.pipelineStatsContainer.click()
        },
    },
}
</script>

<style module src="./PipelineStatsSection.css" />
