<template>
    <div :style="meterStyle" :class="$style.meter">
        <span :style="barStyle"></span>
        <span v-if="showPercentage" :class="$style.centerNumber"
            >{{ Math.round(percentage) }}%</span
        >
    </div>
</template>

<script>
export default {
    name: 'SimpleProgressBar',
    props: {
        test: String,
        total: Number,
        value: Number,
        statusColor: {
            type: Boolean,
            default: false,
        },
        barBackgroundColour: {
            type: String,
        },
        showPercentage: {
            type: Boolean,
            default: true,
        },
        barWidthInPixels: {
            type: Number,
        },
        flatMeterBar: {
            type: Boolean,
        },
    },
    computed: {
        meterStyle() {
            let styleOverrides = {}

            this.barWidthInPixels && this.barWidthInPixels > 0
                ? (styleOverrides = {
                      width: `${this.barWidthInPixels}px`,
                      minWidth: `${this.barWidthInPixels}px`,
                  })
                : {}

            if (this.barBackgroundColour) {
                styleOverrides = { ...styleOverrides, backgroundColor: this.barBackgroundColour }
            }

            return styleOverrides
        },
        percentage() {
            return (this.value / this.total) * 100
        },
        color() {
            if (this.statusColor) {
                switch (true) {
                    case this.total === 0:
                        return 'background-color: #FF224A;'
                    case this.percentage < 30:
                        return 'background-color: #FF224A;'
                    case this.percentage < 99:
                        return 'background-color: #F1B435;'
                    default:
                        return 'background-color: #01E6A5;'
                }
            }
            return 'background-color: #01E6A5;'
        },
        barStyle() {
            const borderRadius = this.flatMeterBar ? '25px 0 0 25px' : '25px'
            return `${this.color} width: ${this.percentage}%; border-radius: ${borderRadius};`
        },
    },
}
</script>

<style module src="./SimpleProgressBar.css" />
