import axios from '@/axios'

const fetchOrgInterviewsByDate = (context, data) => {
    const type = 'fetchOrgInterviewsByDate'
    context.commit('error', { type, status: false })
    context.commit('pending', { type, status: true })

    return axios
        .post('/api/org-interviews', { date: data.date })
        .then((response) => {
            context.commit('storeInterviewsForOrg', response.data)
            context.commit('pending', {
                type,
                status: false,
            })
        })
        .catch(() => {
            context.commit('pending', {
                type,
                status: false,
            })
            context.commit('error', {
                type,
                status: true,
            })
        })
}

export default {
    fetchOrgInterviewsByDate,
}
