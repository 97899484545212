<template>
    <div>
        <NotificationBar v-if="orgNotification" :notification="orgNotification" />
        <PageLayout :wideLayout="true">
            <div :class="$style.column">
                <div :class="$style.mobileOnly">
                    <transition name="slide-fade">
                        <Snackbar
                            v-if="showSnackbar"
                            :class="$style.snackbar"
                            @closeSnackbar="closeSnackbar"
                            message="For the best experience, please use Applied on desktop."
                        />
                    </transition>
                    <div v-if="isOrgAdmin" :class="$style.createRoleWrapperMobile">
                        <JobviteCreateRoleButton v-if="isJobviteOrg" />
                        <CreateRole v-else />
                    </div>
                </div>
                <transition name="slide">
                    <MarkHiredCarousel
                        v-if="showMarkHiredCarousel"
                        :jobsAwaitingHire="jobsAwaitingHire"
                    />
                </transition>
                <div :class="$style.jobListHeader">
                    <div :class="$style.jobHeaderGrid">
                        <h2 :class="$style.jobsHeader">Jobs</h2>
                        <span v-if="isOrgAdmin">
                            <JobviteCreateRoleButton v-if="isJobviteOrg" />
                            <CreateRole v-else />
                        </span>
                        <div :class="$style.searchBox">
                            <FontAwesomeIcon
                                slot="icon"
                                :icon="searchIcon"
                                :class="$style.searchIcon"
                            />
                            <TextInput
                                type="text"
                                v-model="searchQuery"
                                placeholder="search by job title"
                                :class="$style.searchInput"
                                ariaLabel="search by job title"
                            />
                        </div>
                        <div :class="$style.filterSection">
                            <Button
                                :color="activeFilterList.length ? 'primary' : 'secondary'"
                                size="form"
                                @clicked="toggleFilterDropdown()"
                                ref="filterButton"
                                v-click-outside="clickOutsideFilterDropdown"
                            >
                                <span slot="buttonText" :class="$style.filterButton">
                                    Filter by {{ filterCountLabel }}
                                </span>
                                <FontAwesomeIcon
                                    slot="icon"
                                    :icon="chevronDown"
                                    :class="$style.filterIcon"
                                />
                            </Button>
                            <!-- eslint-disable vuejs-accessibility/form-control-has-label -->
                            <Select
                                style="max-width: 250px; margin-left: 12px"
                                :class="$style.sortSelect"
                                name="sort"
                                v-model="sortDirection"
                                :options="[
                                    {
                                        value: 'dateDesc',
                                        text: 'Date created (newest)',
                                    },
                                    {
                                        value: 'dateAsc',
                                        text: 'Date created (oldest)',
                                    },
                                    {
                                        value: 'closesDesc',
                                        text: 'Role close date (newest)',
                                    },
                                    {
                                        value: 'closesAsc',
                                        text: 'Role close date (oldest)',
                                    },
                                ]"
                            />
                            <FilterDropdown
                                v-if="showFilterDropdown"
                                :filters="filters"
                                :state="activeFilters"
                                @filters-changed="filtersChanged"
                                @filters-menu-closed="toggleFilterDropdown"
                                v-click-outside="clickOutsideFilterDropdown"
                                @all-filters-removed="removeAllActiveFilters"
                                style="top: 90%"
                                ref="filterDropdown"
                            />
                        </div>
                    </div>
                    <ActiveFilterList
                        :items="activeFilterList"
                        :jobCount="totalJobCount"
                        @remove-filter="removeActiveFilterItem"
                        @remove-all="removeAllActiveFilters"
                    />
                    <div v-if="pages" :class="$style.pageNumbers">
                        Page {{ currentPage }} of {{ pages }}
                    </div>
                </div>
                <div :class="$style.jobCardWrapper" v-if="loadingJobs">
                    <SkeletonJobCard v-for="index in 10" :key="index" />
                </div>
                <div v-else :class="$style.jobCardWrapper">
                    <div :class="$style.noJobs" v-if="orgJobs.length < 1">
                        There are currently no jobs created...
                    </div>
                    <transition-group name="list" v-else>
                        <SkeletonJobCard v-if="pendingClone" key="dummyKey" />
                        <JobCard
                            :job="job"
                            v-for="job in orgJobs"
                            :key="job.id"
                            @scollToTop="scrollToTop"
                        />
                    </transition-group>
                </div>
                <Pagination
                    v-if="currentPage && pages && pages > 1"
                    :currentPage="currentPage"
                    @pagination="pagination"
                />
            </div>
            <div :class="$style.activityFeedWrapper">
                <OnboardingChecklist v-if="isFreeTrial" />
                <InterviewWidget v-if="isOrgAdmin" />
                <MissingOrgInfoAlert v-if="showMissingOrgInfoAlert" />
                <ActivityFeed />
            </div>
        </PageLayout>
        <NPSModal
            v-if="!NPSdismissed && checkShowNPS"
            @closed="NPSdismissed = true"
            @submitted="NPSsubmitted = true"
        />
    </div>
</template>

<script>
import { debounce } from 'debounce'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFilter, faSearch, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import map from 'ramda/src/map'
import { addMonths, endOfToday, isAfter } from 'date-fns'
import { Button, TextInput, Select } from '@applied/marmot'
import PageLayout from '@/components/PageLayout/PageLayout'
import Snackbar from '@/components/Snackbar/Snackbar'
import FilterDropdown from '@/components/FilterDropdown/FilterDropdown'
import cookies from '@/utils/cookies'
import OnboardingChecklist from '@/components/OnboardingChecklist/OnboardingChecklist'
import NotificationBar from './NotificationBar/NotificationBar'
import JobCard from './JobCard/JobCard'
import ActivityFeed from './ActivityFeed/ActivityFeed'
import SkeletonJobCard from './SkeletonJobCard/SkeletonJobCard'
import CreateRole from './CreateRole/CreateRole'
import JobviteCreateRoleButton from './JobviteCreateRoleButton/JobviteCreateRoleButton'
import NPSModal from './NPSModal/NPSModal'
import MarkHiredCarousel from './MarkHiredCarousel/MarkHiredCarousel'
import helpers from './utils/npsHelpers'
import Pagination from './Pagination/Pagination'
import ActiveFilterList from './ActiveFilterList/ActiveFilterList'
import MissingOrgInfoAlert from './MissingOrgInfoAlert/MissingOrgInfoAlert'
import InterviewWidget from './InterviewWidget/InterviewWidget'

export default {
    name: 'Dashboard',
    data() {
        return {
            roleTitle: '',
            NPSdismissed: false,
            NPSsubmitted: false,
            showSnackbar: true,
            filterOptions: ['draft', 'open', 'closed', 'archived'],
            sortDirection: 'dateDesc',
            showFilterDropdown: false,
            activeFilters: {},
            prevDisabled: true,
            nextDisabled: false,
            currentPage: 1,
            searchQuery: '',
        }
    },
    components: {
        PageLayout,
        JobCard,
        ActivityFeed,
        SkeletonJobCard,
        CreateRole,
        JobviteCreateRoleButton,
        NPSModal,
        MarkHiredCarousel,
        Snackbar,
        OnboardingChecklist,
        FilterDropdown,
        Pagination,
        Button,
        ActiveFilterList,
        FontAwesomeIcon,
        NotificationBar,
        TextInput,
        Select,
        MissingOrgInfoAlert,
        InterviewWidget,
    },
    mounted() {
        const data = this.setSearchQuery()
        this.fetchSearchData(data)
        this.$store.dispatch('fetchJobsAwaitingHire')
        this.$store.dispatch('fetchJobFilters')
        this.trackDailyHit()
    },
    methods: {
        setSearchQuery({
            filter = null,
            filters = null,
            sortDirection = null,
            pageNum = null,
        } = {}) {
            const params = this.$route.query
            const query = {}

            query.sort = sortDirection || params.sort || 'dateDesc'
            query.filter = filter !== null ? filter : params.filter || ''
            query.page = pageNum || parseInt(params.page, 10) || 1

            this.activeFilters = filters || this.activeFilters

            if (query.page < 1 || query.page > this.pages) {
                query.page = 1
            }

            this.currentPage = query.page

            return {
                query,
                filters: this.activeFilterValues,
            }
        },
        pagination({ prev, next, page }) {
            if (!(prev || next || page)) {
                return
            }

            const current = parseInt(this.$route.query.page, 10) || 1
            let pageNum = current

            if (prev) {
                pageNum = current - 1
            } else if (next) {
                pageNum = current + 1
            } else if (page) {
                pageNum = page
            }

            if (pageNum === current) {
                return
            }

            const data = this.setSearchQuery({ pageNum })
            this.fetchSearchData(data)
        },
        scrollToTop() {
            window.scrollTo(0, 0)
        },
        switchSort(sortDirection) {
            const data = this.setSearchQuery({ sortDirection, pageNum: 1 })
            this.fetchSearchData(data)
        },
        fetchSearchData(data) {
            const { query } = data
            this.$router
                .push({
                    query: { ...this.$route.query, ...query },
                })
                .catch(() => {})

            this.$store.dispatch('fetchJobsByOrg', {
                ...data,
                search: this.searchQuery,
            })
        },
        resetCookies() {
            const today = new Date()
            const threeMonthsLater = addMonths(today, 3)
            document.cookie = `npsCount=0`
            document.cookie = `npsTime=${threeMonthsLater}`
        },
        closeSnackbar() {
            this.showSnackbar = false
        },
        toggleFilterDropdown() {
            this.showFilterDropdown = !this.showFilterDropdown
        },
        clickOutsideFilterDropdown(event) {
            const dropdownEls = [this.$refs.filterDropdown, this.$refs.filterButton]
                .filter(Boolean)
                .map((component) => component.$el)

            if (!dropdownEls.some((el) => el.contains(event.target))) {
                this.showFilterDropdown = false
            }
        },
        removeActiveFilterItem(itemToRemove) {
            const newFilters = { ...this.activeFilters }
            newFilters[itemToRemove.filterName] = newFilters[itemToRemove.filterName].filter(
                (item) => item.value !== itemToRemove.value,
            )

            this.filtersChanged(newFilters)
        },
        removeAllActiveFilters() {
            this.filtersChanged({})
        },
        filtersChanged(filters) {
            const data = this.setSearchQuery({ filters, pageNum: 1 })
            this.fetchSearchData(data)
        },
        oldFilterChanged(filter) {
            if (this.selectedFilter === filter) {
                return
            }
            const data = this.setSearchQuery({ filter, pageNum: 1 })
            this.fetchSearchData(data)
        },
        trackDailyHit() {
            const existingCookie = cookies.getCookieByName('loginLastTracked', document.cookie)

            if (existingCookie && isAfter(new Date(), new Date(existingCookie))) {
                this.$store.dispatch('trackCSEvent', {
                    eventId: 'login',
                    value: 1,
                })
                document.cookie = `loginLastTracked=${endOfToday()};SameSite=None; Secure`
            } else if (!existingCookie) {
                document.cookie = `loginLastTracked=${endOfToday()};SameSite=None; Secure`
            }
        },
    },
    computed: {
        userOrg() {
            return this.$store.state.user.Org
        },
        org() {
            return this.$store.state.org
        },
        orgNotification() {
            return (
                this.userOrg &&
                this.userOrg.OrgNotifications &&
                this.userOrg.OrgNotifications.length > 0 &&
                this.userOrg.OrgNotifications[0]
            )
        },
        pages() {
            return this.$store.state.orgJobsPagination && this.$store.state.orgJobsPagination.pages
        },
        selectedFilter() {
            return this.filterOptions.includes(this.$route.query.filter)
                ? this.$route.query.filter
                : ''
        },
        selectedSort() {
            return ['dateDesc', 'dateAsc', 'nameAsc', 'nameDesc'].includes(
                this.$route.query.sortDirection,
            )
                ? this.$route.query.sortDirection
                : 'dateDesc'
        },
        jobCounts() {
            return this.$store.state.jobCounts
        },
        totalJobCount() {
            return this.$store.state.orgJobsPagination && this.$store.state.orgJobsPagination.total
        },
        isOrgAdmin() {
            return this.$store.state.user.role === 'ADMIN'
        },
        loadingJobs() {
            return this.$store.state.pending.fetchJobsByOrg
        },
        pendingClone() {
            return this.$store.state.pending.copyRoleById
        },
        orgJobs() {
            return this.$store.state.orgJobs
        },
        filters() {
            return this.$store.state.jobFilters
        },
        activeFilterList() {
            const augmentedItems = Object.entries(this.activeFilters).map(([filterName, items]) =>
                items.map((item) => ({
                    ...item,
                    filterName,
                })),
            )

            return [].concat(...augmentedItems)
        },
        activeFilterValues() {
            return map((items) => items.map((item) => item.value), this.activeFilters)
        },
        isJobviteOrg() {
            return this.org.id === 619
        },
        checkShowNPS() {
            return helpers.checkShowNPS(new Date(), this.userCreatedDate)
        },
        jobsAwaitingHire() {
            return this.$store.state.jobsAwaitingHire.filter((job) => job.status === null)
        },
        showMarkHiredCarousel() {
            return this.$store.state.jobsAwaitingHire.length > 0
        },
        hasSampleRole() {
            return this.$store.state.orgJobs.filter((job) => job.sampleRole).length > 0
        },
        isFreeTrial() {
            return this.$store.state.freeTrial && this.hasSampleRole
        },
        userCreatedDate() {
            return this.$store.state.user.createdAt
        },
        filterIcon() {
            return faFilter
        },
        searchIcon() {
            return faSearch
        },
        chevronDown() {
            return faChevronDown
        },
        jobTitle() {
            return this.$store.state.user.jobTitle
        },
        userDetailsPending() {
            return this.$store.state.pending.fetchUser
        },
        isMissingOrgData() {
            return (
                !this.org.name ||
                !this.org.contactEmail ||
                !this.org.industry ||
                !this.org.size ||
                !this.org.locale ||
                !this.org.tz
            )
        },
        showMissingOrgInfoAlert() {
            return this.isOrgAdmin && this.isMissingOrgData
        },
        filterCountLabel() {
            return this.activeFilterList.length ? `(${this.activeFilterList.length})` : ''
        },
    },
    watch: {
        sortDirection(sortDirection) {
            this.switchSort(sortDirection)
        },
        NPSdismissed() {
            this.resetCookies()
        },
        NPSsubmitted() {
            this.resetCookies()
        },
        // This needs to be a function not an arrow function for proper this binding
        // eslint-disable-next-line func-names
        searchQuery: debounce(function () {
            const data = this.setSearchQuery({ pageNum: 1 })
            this.fetchSearchData(data)
        }, 200),
    },
    directives: {
        'click-outside': {
            bind(el, binding) {
                const clickEventHandler = (event) => {
                    if (!el.contains(event.target) && el !== event.target) {
                        binding.value(event)
                    }
                }

                // eslint-disable-next-line no-underscore-dangle, no-param-reassign
                el.__clickOutsideHandler = clickEventHandler

                document.addEventListener('click', clickEventHandler)
            },
            unbind(el) {
                // eslint-disable-next-line no-underscore-dangle
                document.removeEventListener('click', el.__clickOutsideHandler)
            },
        },
    },
}
</script>

<style module src="./Dashboard.css" />

<style>
.list-item {
    display: inline-block;
    margin-right: 10px;
}
.list-enter-active,
.list-leave-active {
    transition: all 0.4s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateX(200px);
}
.slide-enter-active,
.slide-leave-active {
    transition: margin-bottom 0.8s ease-out;
}

/*
you set the css property before transition starts
*/
.slide-enter,
.slide-leave-to {
    margin-bottom: -200px;
}

.slide-enter-to,
.slide-leave {
    margin-bottom: 0px;
}

.slide-fade-enter-active {
    transition: all 0.5s ease;
}

.slide-fade-enter {
    transform: translateY(25px);
    opacity: 0;
}

.slide-fade-leave-to {
    transition: all 0.3s ease-out;
    transform: translateY(25px);
    opacity: 0;
}
</style>
