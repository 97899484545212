<template>
    <div :class="[$style.card, $style.leftAlign, $style.standardMarginTop]">
        <VTabs
            v-if="dropoffCharts.length > 0"
            slider-color="#2167C4"
            :class="$style.vTabsArea"
            show-arrows
            next-icon=">"
            prev-icon="<"
        >
            <VTab
                v-for="chart in dropoffCharts"
                :key="`vtab_${chart.name}`"
                :class="$style.demographicTab"
                :active-class="$style.demographicTabActive"
                @click="selectedDropoffCategory = chart.name"
            >
                {{ chart.name }}
            </VTab>
        </VTabs>
        <div :class="$style.cardContent">
            <div v-if="hasDropoffData" :class="$style.dropoffChartsArea">
                <div :class="$style.chartHeader">
                    <h4 :class="[$style.cardTitle, $style.smallMarginBottom]">
                        Pipeline Diversity
                    </h4>
                    <div v-if="hasDropoffData" :class="$style.downloadChartButtonContainer">
                        <MarmotButton
                            color="primaryText"
                            :class="$style.downloadCSVButton"
                            @clicked="downloadCSV"
                        >
                            <span slot="iconLeft" :class="$style.smallerIcon">
                                <FontAwesomeIcon :icon="faArrowToBottom" />
                            </span>
                            <span slot="buttonText">Data CSV</span>
                        </MarmotButton>
                        <MarmotButton
                            color="primaryText"
                            :class="{
                                [$style.downloadChartButton]: true,
                                [$style.downloadChartButtonDisabled]: this.chartIsDownloading,
                            }"
                            @clicked="downloadChart"
                        >
                            <span slot="iconLeft" :class="$style.smallerIcon">
                                <FontAwesomeIcon :icon="faArrowToBottom" />
                            </span>
                            <span slot="buttonText">Graph</span>
                        </MarmotButton>
                        <Tooltip placement="bottom" :class="$style.tooltip">
                            <template v-slot:default>
                                <FontAwesomeIcon :icon="faLightbulbOn" />
                            </template>
                            <template v-slot:inner>
                                <span :class="$style.tooltipElement"
                                    ><strong>What to look for</strong></span
                                ><br />
                                <p :class="$style.tooltipElement">
                                    This chart represents how candidates who applied in your chosen
                                    period moved through your hiring process, broken down by gender.
                                    Check the following:
                                </p>
                                <ul :class="$style.tooltipList">
                                    <li>
                                        Candidate submissions: Are you happy with the diversity of
                                        candidates you are attracting?
                                    </li>
                                    <li>
                                        Bottlenecks: Do you see dramatic changes in representation
                                        from one stage to the other? If yes, consider auditing your
                                        hiring process for potential sources of bias and adverse
                                        impact.
                                    </li>
                                </ul>
                            </template>
                        </Tooltip>
                    </div>
                </div>
                <div :class="$style.separator"></div>
                <div :class="$style.dropoffChartAndBreakdown">
                    <div :class="$style.dropoffChartWrapper">
                        <StackedChart
                            ref="stackedChart"
                            :chartId="`${selectedDropoffCategory}Chart`"
                            :chartData="selectedDropoffChart.data"
                            @chartImage="downloadChartImage"
                        />
                        <div :class="$style.belowChart">
                            <p :class="$style.dropoffChartDescription">
                                Candidates who applied in your chosen period moved through your
                                hiring process, broken down by {{ selectedDropoffChart.key }}.
                            </p>
                            <div :class="$style.quickApplyToggleContainer">
                                <Tooltip
                                    v-if="!enableQuickApplyToggle"
                                    placement="right"
                                    id="disableQuickApplyToggleTooltip"
                                >
                                    <template v-slot:default>
                                        <ToggleSwitch
                                            :disabled="true"
                                            :value="false"
                                            :class="$style.togglefix"
                                        />
                                    </template>
                                    <template v-slot:inner>
                                        To protect candidates and their personal data, you must have
                                        received at least 3 quick-apply applications before you can
                                        use this tool.
                                    </template>
                                </Tooltip>
                                <ToggleSwitch
                                    v-else
                                    :value="!excludeQuickApplyInDropoff"
                                    @toggleChanged="toggleQuickApplyData"
                                    :class="$style.togglefix"
                                />
                                <span :class="$style.quickApplyToggleLabel">
                                    Include quick-apply
                                </span>
                            </div>
                        </div>
                    </div>
                    <div :class="$style.dropoffBreakdownContainer">
                        <h4 :class="$style.breakdownHeader">Breakdown</h4>
                        <VDataTable
                            :headers="tableHeaders"
                            :items="tableData"
                            :class="$style.heatmapTable"
                            disable-pagination
                            hide-default-footer
                            hide-default-header
                        >
                            <template #header="{ props: { headers } }">
                                <thead>
                                    <tr>
                                        <th
                                            v-for="header in headers"
                                            :class="$style.tableHeaderCell"
                                            :key="header.value"
                                        >
                                            <span :class="$style.tableHeaderText">{{
                                                header.text
                                            }}</span>
                                        </th>
                                    </tr>
                                </thead>
                            </template>

                            <template #item="{ item }">
                                <tr :title="item.label">
                                    <td :class="$style.noBorder">
                                        <div :class="$style.flexCell">
                                            <span
                                                :class="$style.tableColorBlob"
                                                :style="getStyleForColourBlob(item)"
                                            ></span>
                                            <span>{{ Math.round(Number(item.submit)) }}%</span>
                                        </div>
                                    </td>
                                    <td :class="$style.noBorder">
                                        <div :class="$style.flexCell">
                                            <span
                                                :class="$style.tableColorBlob"
                                                :style="getStyleForColourBlob(item)"
                                            ></span>
                                            <span>{{ Math.round(Number(item.review)) }}%</span>
                                        </div>
                                    </td>
                                    <td v-if="showCVReviewColumn" :class="$style.noBorder">
                                        <div :class="$style.flexCell">
                                            <span
                                                :class="$style.tableColorBlob"
                                                :style="getStyleForColourBlob(item)"
                                            ></span>
                                            <span>{{ Math.round(Number(item.cvreview)) }}%</span>
                                        </div>
                                    </td>
                                    <td :class="$style.noBorder">
                                        <div :class="$style.flexCell">
                                            <span
                                                :class="$style.tableColorBlob"
                                                :style="getStyleForColourBlob(item)"
                                            ></span>
                                            <span>{{ Math.round(Number(item.interview)) }}%</span>
                                        </div>
                                    </td>
                                    <td :class="$style.noBorder">
                                        <div :class="$style.flexCell">
                                            <span
                                                :class="$style.tableColorBlob"
                                                :style="getStyleForColourBlob(item)"
                                            ></span>
                                            <span>{{ Math.round(Number(item.hired)) }}%</span>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            <template #footer>
                                <div
                                    :class="$style.tableFooter"
                                    v-if="allAvailableTableData.length >= 6"
                                >
                                    <MarmotButton
                                        color="primaryTextOnly"
                                        @clicked="tableExpanded = !tableExpanded"
                                        :class="$style.tableExpandButton"
                                    >
                                        <template #buttonText>
                                            <FontAwesomeIcon
                                                :icon="tableExpanded ? faChevronUp : faChevronDown"
                                        /></template>
                                    </MarmotButton>
                                </div>
                            </template>
                        </VDataTable>
                    </div>
                </div>
            </div>
            <EmptyStateCard v-else :class="$style.dropoffChartEmptyStateCard" />
            <a ref="chartDownloadLink" style="display: none" aria-label="Download chart link"> </a>
        </div>
    </div>
</template>

<script>
import { find, propEq, findIndex, pipe, values, sum, filter, flatten, reverse } from 'ramda'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faChevronDown,
    faChevronUp,
    faArrowToBottom,
    faLightbulbOn,
} from '@fortawesome/pro-light-svg-icons'
import sharedConfig from 'sharedConfig'
import mapIcons from '@/utils/mapIcons'
import { ToggleSwitch, Tooltip, Button as MarmotButton } from '@applied/marmot'
import { VTabs, VTab, VDataTable } from 'vuetify/lib'
import EmptyStateCard from '../EmptyStateCard/EmptyStateCard'
import StackedChart from './StackedChart/StackedChart'
import DropoffChartDisplayConfig from './DropoffChartDisplayConfig'

const localeEqualOpsConfig = sharedConfig.default.localeEqualOps

const chartColours = [
    '#2167C4', // dark blue
    '#5593E8', // light blue
    '#01E6A5', // foam green
    '#008093', // deep green
    '#D68DEB', // violet
    '#FBCBBE', // peach pink
    '#F1B435', // yellow orange
    '#D38201', // ocre orange
    '#5C286B', // dark purple
    '#9553A8', // purple
    '#B6F6AF', // pale green
    '#00A87E', // green
    '#083572', // deep blue
    '#002B49', // midnight
]
const chartColourForDeclined = '#DCDFE4' // gray

export default {
    name: 'PipelineDiversitySection',
    components: {
        ToggleSwitch,
        EmptyStateCard,
        StackedChart,
        VDataTable,
        VTabs,
        VTab,
        Tooltip,
        FontAwesomeIcon,
        MarmotButton,
    },
    props: {
        filters: Object,
        dateRange: Object,
    },
    data() {
        return {
            selectedDropoffCategory: 'Gender',
            grade: 'all',
            tableExpanded: false,
            excludeQuickApplyInDropoff: false,
            chartIsDownloading: false,
        }
    },
    computed: {
        ...mapIcons({
            faChevronDown,
            faChevronUp,
            faArrowToBottom,
            faLightbulbOn,
        }),
        org() {
            return this.$store.state.org
        },
        sourcingCategories() {
            const locale = this.org.locale || 'en-GB'
            const categories = [
                ...Object.keys(
                    filter((demographic) => !!demographic, localeEqualOpsConfig[locale]),
                ),
            ]

            const filteredCategories = filter(
                (val) => categories.includes(val),
                this.sourcingCategoriesMapping,
            )
            return {
                ...filteredCategories,
                performance: 'performance',
            }
        },
        demographicsByStep() {
            const datasetName = this.excludeQuickApplyInDropoff
                ? 'demographicsByStepExcludingQuickApply'
                : 'demographicsByStep'

            return this.$store.state.stats && this.$store.state.stats[datasetName]
                ? this.cleanDemographicData(this.$store.state.stats[datasetName])
                : []
        },
        hasDropoffData() {
            return this.demographicsByStep.find((step) => step.runningTotal > 0)
        },
        selectedDropoffChart() {
            return this.dropoffCharts.find((chart) => chart.name === this.selectedDropoffCategory)
        },
        dropoffCharts() {
            const charts = DropoffChartDisplayConfig

            const localeConfig =
                localeEqualOpsConfig[this.$store.state.org && this.$store.state.org.locale]

            const filteredCharts = localeConfig
                ? charts.filter((chart) => localeConfig[chart.key])
                : charts

            filteredCharts.forEach((chart) => {
                // eslint-disable-next-line no-param-reassign
                chart.data = this.stackedDemographicData(chart.key)
            })

            return filteredCharts
        },
        overallStats() {
            return this.$store.state.stats.overall
        },
        enableQuickApplyToggle() {
            return (
                this.overallStats &&
                this.overallStats.quickApplyCandidates &&
                this.overallStats.quickApplyCandidates >= 1
            )
        },
        tableHeaders() {
            return this.selectedDropoffChart.data.labels.map((label) => {
                return {
                    text: this.capitalise(label),
                    value: label.toLowerCase().replace(/\s/g, ''),
                }
            })
        },
        allAvailableTableData() {
            const orderedData = this.setTableDataColors(
                this.orderTableData(this.selectedDropoffChart.data),
            )

            return orderedData.datasets.map((dataset) => {
                const tableData = {
                    label: dataset.label,
                    backgroundColor: dataset.backgroundColor,
                }

                let index = 0
                this.tableHeaders.forEach((header) => {
                    tableData[header.value] = dataset.data[index]
                    index += 1
                })

                return tableData
            })
        },
        tableData() {
            return this.tableExpanded
                ? this.allAvailableTableData
                : this.allAvailableTableData.slice(0, 6)
        },
        showCVReviewColumn() {
            return this.tableHeaders.some((header) => header.value === 'cvreview')
        },
        startDate() {
            return new Date(this.dateRange.from).toISOString()
        },
        endDate() {
            return new Date(this.dateRange.until).toISOString()
        },
        teamsString() {
            const teams = this.filters?.team?.map((x) => x.value).join(',')
            return teams ? `&teams=${teams}` : ``
        },
        gradesString() {
            const grades = this.filters?.grade?.map((x) => x.value).join('||')
            return grades ? `&grades=${grades}` : ``
        },
    },
    methods: {
        capitalise(text) {
            return `${text[0].toUpperCase()}${text.slice(1).toLowerCase()}`
        },
        cleanDemographicData(demographicData) {
            const startedStep = find(propEq('name', 'started'))(demographicData)
            const indexOfCompletion = findIndex(propEq('name', 'completion'))(demographicData)
            let completionStep = find(propEq('name', 'completion'))(demographicData)
            completionStep = completionStep
                ? { ...completionStep, name: 'submit' }
                : { name: 'submit' }

            const dataWithoutStarted = demographicData.filter((value) => value !== startedStep)
            if (indexOfCompletion !== -1) {
                dataWithoutStarted[indexOfCompletion] = completionStep
            }
            return dataWithoutStarted
        },
        stackedDemographicData(demographicName) {
            const sortedSteps = [...this.demographicsByStep].sort((a, b) => a.step - b.step)

            const characteristics = Array.from(
                new Set(
                    flatten(
                        sortedSteps.map((step) => {
                            const stepData = step.grades[this.grade]
                            return Object.keys((stepData && stepData[demographicName]) || {})
                        }),
                    ),
                ),
            )

            let decline = null
            const orderedCharacteristics = characteristics
                .filter((characteristic) => {
                    if (characteristic.toLowerCase().trim() !== 'decline') {
                        return true
                    }
                    // This saves the exact string of 'decline', in case the
                    // casing is different.
                    decline = characteristic
                    return false
                })
                .sort()

            if (decline) {
                orderedCharacteristics.push(decline)
            }

            const datasets = {}
            orderedCharacteristics.forEach((characteristic) => {
                datasets[characteristic] = {
                    label: characteristic,
                    data: [],
                }
            })

            sortedSteps.forEach((step) => {
                const grade = step.grades[this.grade]
                const demographicData = (grade && grade[demographicName]) || {}

                const characteristicTotal = pipe(values, sum)(demographicData)

                characteristics.forEach((characteristic) => {
                    datasets[characteristic].data.push(
                        ((demographicData[characteristic] || 0) / characteristicTotal || 0) * 100,
                    )
                })
            })

            const hiringSteps = sortedSteps.map((step) => step.name)

            const chartData = {
                labels: hiringSteps,
                datasets: orderedCharacteristics.map((characteristic) => datasets[characteristic]),
            }

            return chartData
        },
        toggleQuickApplyData() {
            this.excludeQuickApplyInDropoff = !this.excludeQuickApplyInDropoff
        },
        getStyleForColourBlob(item) {
            return `background-color: ${item.backgroundColor};`
        },
        orderTableData(data) {
            const declineObject = data.datasets.find((element) => element.label === 'decline')

            if (!declineObject) {
                return data
            }

            const trimmedDatasets = data.datasets.filter((element) => element.label !== 'decline')

            return {
                ...data,
                datasets: [...trimmedDatasets, declineObject],
            }
        },
        setTableDataColors(chartData) {
            return {
                ...chartData,
                datasets: reverse(
                    reverse(chartData.datasets).map((dataset, index) => ({
                        ...dataset,
                        backgroundColor:
                            dataset.label.toLowerCase() === 'decline'
                                ? chartColourForDeclined
                                : chartColours[index],
                    })),
                ),
            }
        },
        downloadChart() {
            this.chartIsDownloading = true
            this.$refs.stackedChart.downloadChartImage()
        },
        downloadChartImage(imageString) {
            const link = this.$refs.chartDownloadLink
            link.href = imageString
            link.download = `Pipeline Diversity Chart - ${this.selectedDropoffCategory}.png`
            link.click()
            this.chartIsDownloading = false
        },
        downloadCSV() {
            window.open(
                `/api/org/org-drop-off/${this.selectedDropoffChart.key}/csv?from=${
                    this.startDate
                }&until=${this.endDate}${this.teamsString}${this.gradesString}`,
            )
        },
    },
}
</script>

<style module src="./PipelineDiversitySection.css" />
