<template>
    <div :class="$style.reviewProgressContainer">
        <div :class="$style.reviewProgressHeader">
            <div :class="$style.reviewProgressTooltipHeader">Review progress</div>
            <div>
                <a :href="manageReviewProgressUrl" target="_blank">
                    <FontAwesomeIcon :class="$style.externalLinkIcon" :icon="faExternalLinkAlt" />
                </a>
            </div>
            <div :class="$style.closeTooltipIcon" @click="closeTooltip">
                <FontAwesomeIcon :icon="faTimesCircle" />
            </div>
        </div>
        <div v-if="showSiftProgress">
            <div :class="$style.progressBarContainer">
                <div :class="$style.progressBarTextLarge">Sift</div>
                <SimpleProgressBar
                    :class="$style.progressBar"
                    :total="reviewProgress.sift.totalReviewCount"
                    :value="reviewProgress.sift.scoredReviewCount"
                    :showPercentage="false"
                    barBackgroundColour="#FFFFFF"
                    :flatMeterBar="true"
                />
                <div :class="$style.progressBarTextLarge">{{ reviewProgressSiftPercentage }}%</div>
                <div v-if="allSiftReviewsComplete">
                    <FontAwesomeIcon :class="$style.allReviewsCompleteIcon" :icon="faCheckCircle" />
                </div>
            </div>
            <div :class="$style.reviewProgressLastAllocated">
                Last allocated: {{ getReviewsLastAllocated(reviewProgress.sift.lastBatchSentAt) }}
            </div>
        </div>
        <hr v-if="showSiftProgress && showCvProgress" :class="$style.reviewProgressSeparator" />
        <div v-if="showCvProgress">
            <div :class="$style.progressBarContainer">
                <div :class="$style.progressBarTextLarge">CV</div>
                <SimpleProgressBar
                    :class="$style.progressBar"
                    :total="reviewProgress.cv.totalReviewCount"
                    :value="reviewProgress.cv.scoredReviewCount"
                    :showPercentage="false"
                    barBackgroundColour="#FFFFFF"
                    :flatMeterBar="true"
                />
                <div :class="$style.progressBarTextLarge">{{ reviewProgressCvPercentage }}%</div>
                <div v-if="allCvReviewsComplete">
                    <FontAwesomeIcon :class="$style.allReviewsCompleteIcon" :icon="faCheckCircle" />
                </div>
            </div>
            <div :class="$style.reviewProgressLastAllocated">
                Last allocated: {{ getReviewsLastAllocated(reviewProgress.cv.lastBatchSentAt) }}
            </div>
        </div>
    </div>
</template>

<script>
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { faExternalLinkAlt, faTimesCircle } from '@fortawesome/pro-light-svg-icons'
import mapIcons from '@/utils/mapIcons'
import SimpleProgressBar from '@/components/SimpleProgressBar/SimpleProgressBar'

export default {
    name: 'ReviewProgressToolTip',
    emits: ['close'],
    components: {
        FontAwesomeIcon,
        faExternalLinkAlt,
        faCheckCircle,
        faTimesCircle,
        SimpleProgressBar,
    },
    props: {
        reviewProgress: Object,
        jobId: Number,
    },
    computed: {
        ...mapIcons({ faCheckCircle, faTimesCircle, faExternalLinkAlt }),
        manageReviewProgressUrl() {
            return `/manage/${this.jobId}/review-progress`
        },
        reviewProgressSiftPercentage() {
            return Math.floor(
                (this.reviewProgress.sift.scoredReviewCount / this.reviewProgress.sift.totalReviewCount) *
                    100,
            )
        },
        reviewProgressCvPercentage() {
            return Math.floor(
                (this.reviewProgress.cv.scoredReviewCount / this.reviewProgress.cv.totalReviewCount) *
                    100,
            )
        },
        allSiftReviewsComplete() {
            return (
                this.reviewProgress.sift.totalReviewCount > 0 &&
                this.reviewProgress.sift.scoredReviewCount === this.reviewProgress.sift.totalReviewCount
            )
        },
        allCvReviewsComplete() {
            return (
                this.reviewProgress.cv.totalReviewCount > 0 &&
                this.reviewProgress.cv.scoredReviewCount === this.reviewProgress.cv.totalReviewCount
            )
        },
        showSiftProgress() {
            return this.reviewProgress.sift?.totalReviewCount > 0
        },
        showCvProgress() {
            return this.reviewProgress.cv?.totalReviewCount > 0
        },
    },
    methods: {
        closeTooltip() {
            this.$emit('close')
        },
        getReviewsLastAllocated(lastAllocatedDate) {
            const formattedDate = format(
                new Date(lastAllocatedDate),
                'MM/dd/yy hh:mmaaa',
            )
            return formattedDate.replace(/(am|pm)/i, (match) => match.toUpperCase())
        }
    },
}
</script>

<style module src="./ReviewProgressToolTip.css" />
