<template>
    <div :class="$style.container">
        <div :class="$style.headerRow">
            <div :class="$style.tooltipHeader">
                <h2 :class="$style.header">Interviews</h2>
                <a :href="interviewUrl" target="_blank" >
                    <FontAwesomeIcon :icon="faExternalLinkAlt" :class="$style.iconCTA" />
                </a>
            </div>

            <FontAwesomeIcon :icon="faTimesCircle" :class="$style.iconCTA" @click="$emit('close')"/>
        </div>
        <div v-for="interview in progress" :key="interview.name" :class="$style.interviewStatWrapper">
            <div :class="$style.progressHeaderRow">
                <span :title="interview.name">
                    {{ trimInterviewName(interview.name) }}
                </span>
            </div>
            <div :class="$style.progress">
                <div :class="$style.interviewStat">
                    <div :class="[$style.pip, $style.invited]"></div>
                    {{ interview.invited || 0 }} invited
                </div>
                <div :class="$style.interviewStat">
                    <div :class="[$style.pip, $style.booked]"></div>
                    {{ interview.booked || 0 }} booked
                </div>
                <div :class="$style.interviewStat">
                    <div :class="[$style.pip, $style.done]"></div>
                    {{ interview.done || 0 }} done (scored)
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faExternalLinkAlt, faTimesCircle } from '@fortawesome/pro-light-svg-icons'
import SimpleProgressBar from '@/components/SimpleProgressBar/SimpleProgressBar'
import mapIcons from '@/utils/mapIcons'


export default {
    name: 'InterviewProgressTooltip',    
    components: {
        SimpleProgressBar,
        FontAwesomeIcon,
    },
    props: {
        progress: {
            type: Array,
            default: () => [],
        },
        jobId: Number
    },
    computed: {
        ...mapIcons({ faExternalLinkAlt, faTimesCircle }),
        interviewUrl() {
            return `/manage/${this.jobId}/calendar`
        }
    },
    methods: {
        trimInterviewName(name) {
            return name.length > 20 ? `${name.slice(0, 20)}...` : name
        }
    }
}
</script>

<style module src="./InterviewProgressTooltip.css" />
